import { PageElement, html, css } from 'Elements';
import { Router } from 'Router';

class LogoutPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .container {
          padding:20px;
          text-align:center;
          font-size:1em;
        }

        .container m-icon {
          display:block;
          text-align:center;
          font-size:60px;
          color:var(--sl-color-danger-500);
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Goodbye',
            text:'You have been successfully disconnected.',
            back:'Back to home',
          }
        },
        french:{
          translation: {
            title:'A bientôt',
            text:'Vous avez été déconnecté avec succès.',
            back:'Retourner à l\'accueil',
          }
        }
      }
    ]
  }

  render() {
    return html`
      <section-header micon="error">${this._tl('title')}</section-header>
      <br/>
      <div class="container">
        <m-icon name="move_item"></m-icon>
        <p>${this._tl('text')}</p>
        <br/>
        <sl-button @click=${this.goHome} size="medium">${this._tl('back')}</sl-button>
      </div>
    `;
  }

  goHome() {
    Router.go('/');
  }

}

customElements.define('page-user-logout', LogoutPage);