import { PageElement, html, css } from 'Elements';
import { UrlParams, Fetcher } from 'Utils';

class ValidationEmailPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        m-icon {
          font-size: 3em;
        }

        .message {
          font-size: 1.9em;
          text-align: center;
          display:flex;
          justify-content:center;
          flex:1;
          flex-direction:column;
          max-width:600px;
          width:90vw;
          margin-left:auto;
          margin-right:auto;
          padding:20px;
        }

        .green {
          color:var(--sl-color-green-700);
        }

        .red {
          color:var(--sl-color-danger-700);
        }

        sl-progress-bar {
          flex:1;
        }

        .comment {
          font-size:1rem;
          color:var(--sl-color-gray-500);
        }

        ul {
          text-align:left;
          margin-left:20px;
        }
      `
    ]
  }

  static get properties() {
    return {
      checking: { type: Boolean },
      error: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.checking = true;
    this.error = false;
  }

  async firstUpdated() {
    this.cid = UrlParams.get('cid');
    this.type = UrlParams.get('t');
    setTimeout(async () => {
      const response = await Fetcher.post('public/validation/email', { cid:this.cid, type:this.type });
      if (!response || !response.ok) this.error = true;
      this.checking = false;
    }, 1000);
  }

  renderChecking() {
    if (!this.checking) return '';
    return html`
      <div class="message">
        <div>Vérification en cours...</div>
        <br/>
        <div><sl-progress-bar indeterminate></sl-progress-bar></div>
      </div>
    `;
  }

  renderValidated() {
    if (this.error) return '';
    if (this.checking) return '';
    return html`
      <div class="message">
        <div>Votre adresse email a bien été validée.<br/><br/></div>
        <m-icon name="mark_email_read" class="green"></m-icon>
        <br/>
        <div class="comment">Merci. Vous pouvez maintenant quitter cette page ou retourner à l'accueil.</div>
      </div>
    `;
  }

  renderError() {
    if (!this.error) return '';
    return html`
      <div class="message">
        <div>Une erreur est survenue.<br/></div>
        <br/>
        <m-icon name="pulse_alert" class="red"></m-icon>
        <br/>
        <div class="comment">
          Nous n'avons pas pu valider votre adresse email pour une des raisons suivantes :
          <ul>
            <li>Le token de validation est incorrect ou a expiré.</li>
            <li>Le temps impartie pour la validation a été dépassé.</li>
          </ul>
        </div>
      </div>
    `;
  }
  
  render() {
    return html`
      <section-header micon="verified">Validation de votre adresse email</section-header><br/>
      <br/>
      ${this.renderChecking()}
      ${this.renderValidated()}
      ${this.renderError()}
      <br/>
    `;
  }
}

customElements.define('page-validation-email', ValidationEmailPage);